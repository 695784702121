"use client";

import Image from "next/image";

import icAde from "@/public/images/team/icon/ic_ade.png";
import icBrb from "@/public/images/team/icon/ic_brb.png";
import icBrl from "@/public/images/team/icon/ic_brl.png";
import icCar from "@/public/images/team/icon/ic_car.png";
import icCol from "@/public/images/team/icon/ic_col.png";
import icEss from "@/public/images/team/icon/ic_ess.png";
import icFre from "@/public/images/team/icon/ic_fre.png";
import icGcs from "@/public/images/team/icon/ic_gcs.png";
import icGee from "@/public/images/team/icon/ic_gee.png";
import icGws from "@/public/images/team/icon/ic_gws.png";
import icHaw from "@/public/images/team/icon/ic_haw.png";
import icMel from "@/public/images/team/icon/ic_mel.png";
import icNth from "@/public/images/team/icon/ic_nth.png";
import icPad from "@/public/images/team/icon/ic_pad.png";
import icRch from "@/public/images/team/icon/ic_rch.png";
import icSme from "@/public/images/team/icon/ic_stk.png";
import icStk from "@/public/images/team/icon/ic_stk.png";
import icSyd from "@/public/images/team/icon/ic_syd.png";
import icUni from "@/public/images/team/icon/ic_uni.png";
import icWbd from "@/public/images/team/icon/ic_wbd.png";
import icWce from "@/public/images/team/icon/ic_wce.png";
import icUnk from "@/public/images/team/icon/ic_unk.png";

const icons = {
  ADE: icAde,
  BRB: icBrb,
  BRL: icBrl,
  CAR: icCar,
  COL: icCol,
  ESS: icEss,
  FRE: icFre,
  GCS: icGcs,
  GEE: icGee,
  GWS: icGws,
  HAW: icHaw,
  MEL: icMel,
  NTH: icNth,
  PAD: icPad,
  RCH: icRch,
  SME: icSme,
  STK: icStk,
  SYD: icSyd,
  UNI: icUni,
  WBD: icWbd,
  WCE: icWce,
  UNK: icUnk,
};

interface TeamLogoProps {
  teamAbbrev: string | null | undefined;
  width?: number;
  height?: number;
}

export const TeamLogo = ({
  teamAbbrev,
  width = 48,
  height = 48,
}: TeamLogoProps) => {
  // Handle null or undefined teamAbbrev gracefully
  const abbrev = teamAbbrev ? teamAbbrev.toUpperCase() : "UNK";

  return (
    <Image
      className={`flex-grow-0 flex-shrink-0`}
      src={icons.hasOwnProperty(abbrev) ? icons[abbrev] : icons.UNK}
      width={width}
      height={height}
      alt={teamAbbrev || "Unknown team"}
    />
  );
};
