import { useQuery } from "@tanstack/react-query";
import { useApi } from "@/common/api";
import {
  ResultsSummary,
  ResultsSummaryCompetition,
  ResultsSummaryLeague,
  ResultsSummaryRound,
  ResultsSummarySeason,
  ResultsSummaryTeam,
} from "../types";
import { UseQueryOptions, keepPreviousData } from "@tanstack/react-query";

// Response and params types
type ResultsSummaryParams =
  | {
      round_id?: number;
      league_ids?: string;
      season_id?: number;
      competition_ids?: number[];
    }
  | {
      team_id: number;
      competition_ids: number[];
    }
  | null;

interface LeaguesResponse {
  leagues: ResultsSummaryLeague[];
}

interface SeasonsResponse {
  seasons: ResultsSummarySeason[];
}

interface SeasonsParams {
  league_id: string;
}

interface CompetitionsResponse {
  competitions: ResultsSummaryCompetition[];
}

interface CompetitionsParams {
  league_id: string;
  season_id: number;
}

export type RoundsParams =
  | {
      competition_ids: number[];
    }
  | { season_id: number; league_id: string };

interface RoundsResponse {
  rounds: ResultsSummaryRound[];
}

export interface TeamsParams {
  competition_ids: number[];
}

interface TeamsResponse {
  teams: ResultsSummaryTeam[];
}

// Helper function to convert params to query string
function getQueryString(params: Record<string, any> | null): string {
  if (!params) return "";
  return `?${Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      if (key === "league_ids") {
        return `${key}=${encodeURIComponent(value)}`;
      }
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${encodeURIComponent(v)}`).join("&");
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&")}`;
}

// Base service functions
function createResultsService(api: ReturnType<typeof useApi>) {
  return {
    fetchResultsSummary: async (
      params: ResultsSummaryParams
    ): Promise<ResultsSummary> => {
      console.log("Fetching results with params: ", params);
      const queryString = getQueryString(params as Record<string, any>);
      const json = await api.get<ResultsSummary>(
        `/round_summary${queryString}`,
        { authenticated: false }
      );
      return json;
    },

    fetchLeagues: async (): Promise<LeaguesResponse> => {
      const json = await api.get<LeaguesResponse>("/leagues", {
        authenticated: false,
      });
      return json;
    },

    fetchSeasons: async (params: SeasonsParams): Promise<SeasonsResponse> => {
      const queryString = getQueryString(params);
      const json = await api.get<SeasonsResponse>(`/seasons${queryString}`, {
        authenticated: false,
      });
      return json;
    },

    fetchCompetitions: async (
      params: CompetitionsParams
    ): Promise<CompetitionsResponse> => {
      const queryString = getQueryString(params);
      const json = await api.get<CompetitionsResponse>(
        `/competitions${queryString}`,
        { authenticated: false }
      );
      console.log("Found competitions: ", json);
      return json;
    },

    fetchRounds: async (params: RoundsParams): Promise<RoundsResponse> => {
      console.log("Rounds params in fetch: ", params);
      const queryString = getQueryString(params);
      const json = await api.get<RoundsResponse>(`/rounds${queryString}`, {
        authenticated: false,
      });
      console.log("Rounds found: ", json);
      return json;
    },

    fetchTeams: async (params: TeamsParams): Promise<TeamsResponse> => {
      const queryString = getQueryString(params);
      const json = await api.get<TeamsResponse>(`/teams${queryString}`, {
        authenticated: false,
      });
      console.log("Teams found: ", json);
      return json;
    },
  };
}

// Hook to use the service
export function useResultsService() {
  const api = useApi();
  return createResultsService(api);
}

// React Query hooks for data fetching
export function useResultsSummary(
  params: ResultsSummaryParams | null,
  options?: Partial<
    UseQueryOptions<
      ResultsSummary,
      Error,
      ResultsSummary,
      (string | ResultsSummaryParams | null)[]
    >
  >
) {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["resultsSummary", params],
    queryFn: () =>
      resultsService.fetchResultsSummary(params as ResultsSummaryParams),
    enabled: !!params,
    staleTime: 30000,
    placeholderData: keepPreviousData,
    ...options,
  });
}

export function useLeagues() {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["leagues"],
    queryFn: () => resultsService.fetchLeagues(),
    staleTime: 60000,
  });
}

export function useSeasons(leagueId: string | undefined) {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["seasons", leagueId],
    queryFn: () =>
      resultsService.fetchSeasons({ league_id: leagueId as string }),
    enabled: !!leagueId,
    staleTime: 60000,
  });
}

export function useCompetitions(
  leagueId: string | undefined,
  seasonId: number | undefined
) {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["competitions", leagueId, seasonId],
    queryFn: () =>
      resultsService.fetchCompetitions({
        league_id: leagueId as string,
        season_id: seasonId as number,
      }),
    enabled: !!leagueId && !!seasonId,
    staleTime: 60000,
  });
}

export function useRounds(params: RoundsParams | null) {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["rounds", params],
    queryFn: () => resultsService.fetchRounds(params as RoundsParams),
    enabled: !!params,
  });
}

export function useTeams(params: TeamsParams | null) {
  const resultsService = useResultsService();

  return useQuery({
    queryKey: ["teams", params],
    queryFn: () => resultsService.fetchTeams(params as TeamsParams),
    enabled: !!params,
  });
}
